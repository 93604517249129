<template>
  <div class="center-main">
    <div v-if="!errorShow">
      <loading :loadingShow="loadingShow"></loading>
      <div v-if="!loadingShow">
        <tab-left
          :tabs="formGroupList"
          :value="labelId"
          :tableftdata="tableftdata"
          @edit="editFormGroup"
          @del="delFormGroup"
          @cutList="cutList"
          @create="createFormGroup"
        />
        <div class="left-content">
          <div class="filter-form">
            <div class="title">行为数据</div>
            <div class="butn">
              <xk-button
                type="primary"
                @click="addSchedule"
                v-hasPermi="['behaviorData:save']"
              >
                <img :src="plusUrl" alt="plus" style="margin-right: 8px" />
                <span>添加</span>
              </xk-button>
            </div>
          </div>
          <table-data
            ref="tableRef"
            v-loading="loadingTable"
            id="table"
            :config="table_config"
            :tableData="table_data"
            :backgroundHeader="backgroundHeader"
            style="margin-top: -10px"
          >
            <template v-slot:operation="slotData">
              <el-button
                v-hasPermi="['behaviorData:edit']"
                type="text"
                class="primary"
                @click="edit(slotData.data)"
                >编辑</el-button
              >
              <el-button
                v-hasPermi="['behaviorData:del']"
                type="text"
                class="danger"
                @click="del(slotData.data)"
                >删除</el-button
              >
            </template>
          </table-data>
          <Pagination
            :total="total"
            :page.sync="listQuery.pageNum"
            :limit.sync="listQuery.pageRow"
            @pagination="getList"
          />
          <dialog-wrapper
            :dialogObj="dialogObj"
            @handleClose="handleFormGroupClose"
          >
            <el-form
              :model="formGroupRuleForm"
              :rules="formGroupRules"
              ref="ruleForm"
              label-width="120px"
            >
              <el-form-item label="行为标签名称" prop="name">
                <el-input
                  v-model="formGroupRuleForm.name"
                  placeholder="请输入"
                  maxlength="10"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="类型" prop="type">
                <el-select v-model="formGroupRuleForm.type" style="width: 100%">
                  <el-option value="1" label="教师标签"></el-option>
                  <el-option value="2" label="学生标签"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选项类型" prop="optionType">
                <el-radio-group v-model="formGroupRuleForm.optionType">
                  <el-radio label="1">单项选择</el-radio>
                  <el-radio label="2">多项选择</el-radio>
                </el-radio-group>
              </el-form-item>
              <div :style="{ 'text-align': 'center' }">
                <xk-button type="primary" @click="handleFormGroupSave"
                  >确定</xk-button
                >
                <xk-button type="info" @click="handleFormGroupClose"
                  >取消</xk-button
                >
              </div>
            </el-form>
          </dialog-wrapper>
          <dialog-wrapper
            :dialogObj="dialogObj1"
            @handleClose="handleFormGroupClose1"
          >
            <el-form
              v-if="dialogObj1.dialogVisible"
              :model="behaviorData"
              :rules="behaviorDataRules"
              ref="ruleForm1"
              label-width="120px"
            >
              <el-form-item label="数据名称" prop="dataName">
                <el-input
                  v-model="behaviorData.dataName"
                  placeholder="请输入"
                  maxlength="20"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="排序" prop="sort">
                <el-input
                  v-model="behaviorData.sort"
                  placeholder="请输入"
                  maxlength="3"
                  oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="isDefault">
                <el-checkbox
                  v-model="behaviorData.isDefault"
                  true-label="1"
                  false-label="0"
                  >默认选项
                </el-checkbox>
              </el-form-item>
              <div :style="{ 'text-align': 'center' }">
                <xk-button type="primary" @click="changeGrouping"
                  >确定</xk-button
                >
                <xk-button type="info" @click="handleFormGroupClose1"
                  >取消</xk-button
                >
              </div>
            </el-form>
          </dialog-wrapper>
        </div>
      </div>
    </div>
    <error v-else :errorShow="errorShow"></error>
  </div>
</template>

<script>
import {
  // 业务组件
  FilterData,
//   TableData,
  Pagination,
  // 功能组件
  Loading,
  Error,
  DialogWrapper,
  // 工具函数
  throttle,
  debounce,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData"
import tabLeft from "./tabLeft";
import { mapState } from "vuex";
import { classScheduleModel } from "@/models/ClassSchedule.js";
import Sortable from "sortablejs";
import { hasPermission } from "@/libs/utils";
export default {
  name: "systemRoleScrollWrapper",
  components: {
    tabLeft,
    FilterData,
    TableData,
    Pagination,
    DialogWrapper,
    Loading,
    Error,
  },
  props: {
    accessStatus: String,
  },
  data() {
    return {
      groupValue: "",
      groupingOptions: [],
      mobileGroupingRow: {},
      formGroupList: [],
      //左侧添加按钮数据
      formGroupRuleForm: {
        name: "",
        type: "1",
        optionType: "1",
        schoolId: "",
        id: "",
      },
      //右侧添加按钮数据
      behaviorData: {
        labelId: "",
        dataName: "",
        sort: "",
        isDefault: "0",
        id: "",
      },
      formGroupRules: {
        name: [
          { required: true, message: "请输入行为标签名称", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        optionType: [
          { required: true, message: "请选择选项类型", trigger: "blur" },
        ],
      },
      behaviorDataRules: {
        dataName: [
          { required: true, message: "请输入数据名称", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },

      // 表格配置
      table_config: {
        thead: [
          {
            label: "行为数据",
            prop: "dataName",
            type: "popoverText",
            align: 'center',
            labelWidth: '220px'
          },
          {
            label: "排序",
            prop: "sort",
            align: "center",
            labelWidth: '120px',
            className: 'text-spacing-reduction'
          },
          {
            label: "是否默认选项",
            align: 'center',
            type: "function",
            callBack(row) {
              if (row.isDefault == 1) {
                return "是";
              } else if (row.isDefault == 0) {
                return "否";
              }
            },
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: 'center',
            type: "function",
                callBack(row) {
                    return row.createTime ? row.createTime.slice(0, 16):'-'
                },
          },
          {
            label: "修改时间",
            prop: "updateTime",
            align: 'center',
            type: "function",
                callBack(row) {
                    return row.updateTime ? row.updateTime.slice(0, 16):'-'
                },
          },
          {
            label: "操作",
            type: "slot",
            align: 'center',
            fixed: "right",
            slotName: "operation",
            className: 'text-spacing-reduction'
          },
        ],
        rowkey: "id",
        check: false,
        height: "",
      },
      // 表格数据
      table_data: [],
      // 分页器
      total: 0,
      listQuery: {
        pageNum: 1,
        pageRow: 20,
        labelId: "",
      },

      // 功能组件
      errorShow: false,
      loadingShow: false,
      loadingTable: false,

      // 表头
      backgroundHeader: "#FAFBFC",
      // 表头字体
      headerColor: "",
      template_info: [],
      // 左侧【添加/编辑】
      dialogObj: {
        title: "",
        dialogVisible: false,
        width: "500px",
      },
      //列表页【添加/编辑】
      dialogObj1: {
        title: "",
        dialogVisible: false,
        width: "500px",
      },
      schoolIdObj: {
        schoolId: "",
      },
      groupingStatus: "add",
      overallDisplayData: {},
      videoListarr: [],
      //左侧行为标签列表数据
      tableftdata: [],
      labelId: "",
      dataEdit: "",
    };
  },
  computed: {
    ...mapState({
      templateInfo: (state) => state.templateInfo,
      schoolId: (state) => state.schoolId,
      permissions: (state) => state.permissions,
      oaManagementListLeftIds: (state) => state.oaManagementListLeftIds,
    }),
    plusUrl() {
      return require("@/assets/images/plus.png");
    },
  },
  created() {
    // this.listQuery.schoolId = this.$store.state.schoolId;
    this.tableftInit();
  },
  mounted() {
    setTimeout(() => {
      this.table_config.height =
        document.body.clientHeight -
        document.getElementById("table").offsetTop -
        140;
      window.onresize = debounce(() => {
        if (!document.getElementById("table")) return;
        const height =
          document.body.clientHeight -
          document.getElementById("table").offsetTop -
          140;
        this.table_config.height = height.toString();
      }, 200);
    }, 100);
  },
  methods: {
    getList() {
      if (this.tableftdata.length > 0) {
        if (this.labelId) {
          this.listQuery.labelId = this.labelId;
        } else {
          this.listQuery.labelId = this.tableftdata[0].id;
        }
        const classSchedule = new classScheduleModel();
        this.loadingTable = true;
        classSchedule.getBehaviorDataList(this.listQuery).then((res) => {
          this.handleRes(res, () => {
            this.table_data = res.data.data.list;
            this.total = res.data.data.totalCount;
          });
          this.loadingTable = false;
        }).catch(err => {
          this.loadingTable = false;
        })
      }else{
        this.loadingTable = false
      }
    },
    cutList(data) {
      console.log(this.labelId, "222");

      this.labelId = data.id;
      this.listQuery.labelId = this.labelId;
      const classSchedule = new classScheduleModel();
      classSchedule
        .getBehaviorLook({
          labelId: data.id,
        })
        .then((res) => {
          this.handleRes(res, () => {
            console.log(res, "行为数据11");
            this.table_data = res.data.data;
          });
        });
    },

    /**
     * @Description: 左侧行为标签列表数据
     * @DoWhat: 行为标签列表
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-14 16:59:08
     */
    async tableftInit() {
      this.loadingTable = true;
      const classSchedule = new classScheduleModel();
      classSchedule
        .getBehaviorLabelList({ schoolId: this.schoolId })
        .then((res) => {
          this.handleRes(res, () => {
            this.tableftdata = res.data.data;
            if (this.tableftdata.length > 0) {
              this.labelId = this.tableftdata[0].id;
            }
            this.getList();
          });
        });
    },

    /**
     * @Description: 左侧栏编辑
     * @DoWhat: 编辑
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 16:42:15
     */
    editFormGroup(data) {
      console.log(data, "0000000");
      this.formGroupRuleForm.name = data.name;
      this.formGroupRuleForm.id = data.id;
      this.formGroupRuleForm.type = String(data.type);
      this.formGroupRuleForm.optionType = String(data.optionType);
      this.dialogObj.title = "编辑";
      this.groupingStatus = "edit";
      this.dialogObj.dialogVisible = true;
    },
    /**
     * @Description: 关闭、取消添加/编辑弹框
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 16:43:57
     */
    handleFormGroupClose() {
      this.$refs.ruleForm.resetFields(); // 清空校验
      this.dialogObj.dialogVisible = false;
      Object.keys(this.formGroupRuleForm).forEach((key) => {
        this.formGroupRuleForm[key] = "";
      });
      // this.formGroupRuleForm.isDefault = "0";
      console.log(this.formGroupRuleForm, 'this.behaviorData')
    },
    /**
     * @Description: 左侧栏删除
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 16:59:46
     */
    delFormGroup(data) {
      const h = this.$createElement;
      this.$confirm("提示", {
        title: "确认提示",
        message: h("div", [
          h("p", "是否确定删除?"),
          h("p", "信息删除后不可恢复"),
        ]),
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const param = {
            id: data.id,
          };
          this._fet("/school/schoolBehaviorLabel/delete", param).then((res) => {
            this.handleRes(res, () => {
              this.$message.success("删除成功!");
              this.tableftInit();
            });
          });
        })
        .catch(() => {
          // 取消
        });
    },
    /**
     * @Description: 行为数据-添加
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 17:01:30
     */
    addSchedule() {
      console.log(this.labelId, this.behaviorData.id, "你说有值111吗");
      this.dialogObj1.title = "添加";
      this.dialogObj1.dialogVisible = true;
      this.behaviorData.id = "";
    },
    /**
     * @Description: 行为数据添加/编辑-取消
     * @DoWhat: 添加/编辑-取消
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-15 14:53:48
     */
    handleFormGroupClose1() {
      this.$refs.ruleForm1.resetFields();
      this.dialogObj1.dialogVisible = false;
      Object.keys(this.behaviorData).forEach((key) => {
        this.behaviorData[key] = "";
      });
      this.behaviorData.isDefault = "0";
      console.log(this.behaviorData, 'behaviorData')
    },
    /**
     * @Description: 行为数据添加/编辑-保存
     * @DoWhat: 添加/编辑-保存
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-15 14:52:40
     */
    changeGrouping() {
      console.log('00000--')
      this.$refs["ruleForm1"].validate((valid) => {
        if (valid) {
          const classSchedule = new classScheduleModel();
          console.log(this.tableftdata, "this.tableftdata");
          //如果有行为标签
          if (this.tableftdata.length > 0) {
            if (this.labelId) {
              this.behaviorData.labelId = this.labelId;
            } else {
              this.behaviorData.labelId = this.tableftdata[0].id;
            }
            let arr = [];
            this.tableftdata.forEach((item) => {
              if (this.behaviorData.labelId == item.id) {
                //单选
                if (item.optionType == "1") {
                  this.table_data.map((it) => {
                    if (it.isDefault == "1") {
                      arr.push({ isDefault: it.isDefault, id: it.id });
                    }
                    console.log(it.isDefault);
                  });
                  console.log(arr, "123");
                  //如果默认多余一个
                  if (arr.length >= 1) {
                    //添加时
                    if (
                      this.behaviorData.isDefault == "1" &&
                      (this.behaviorData.id == "" ||
                        this.behaviorData.id != arr[0].id)
                    ) {
                      this.$message.error(
                        "只能有一个行为数据可设置为默认选项！"
                      );
                    } else if (
                      this.behaviorData.isDefault == "1" &&
                      this.behaviorData.id == arr[0].id
                    ) {
                      console.log('111--')
                      classSchedule
                        .getBehaviorDataSave(this.behaviorData)
                        .then((res) => {
                          this.handleRes(res, () => {
                            this.getList();
                            this.handleFormGroupClose1();
                          });
                        });
                    } else {
                      console.log('222')
                      console.log(this.labelId, "你说有值吗");

                      classSchedule
                        .getBehaviorDataSave(this.behaviorData)
                        .then((res) => {
                          this.handleRes(res, () => {
                            this.getList();
                            this.handleFormGroupClose1();
                          });
                        });
                    }
                  } else {
                    console.log('333')
                    console.log(this.labelId, "你说有值吗");
                    const classSchedule = new classScheduleModel();
                    classSchedule
                      .getBehaviorDataSave(this.behaviorData)
                      .then((res) => {
                        this.handleRes(res, () => {
                          this.getList();
                          this.handleFormGroupClose1();
                        });
                      });
                  }
                } else {
                  console.log('444')
                  console.log(this.labelId, "你说有值吗");
                  const classSchedule = new classScheduleModel();
                  classSchedule
                    .getBehaviorDataSave(this.behaviorData)
                    .then((res) => {
                      this.handleRes(res, () => {
                        this.getList();
                        this.handleFormGroupClose1();
                      });
                    });
                }
              }
            });
          } else {
            this.$message.warning("请先设置行为标签");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * @Description: 左侧栏添加按钮保存
     * @DoWhat: 添加-确定
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-15 10:16:22
     */
    handleFormGroupSave() {
      console.log('编辑321')
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log(this.table_data, "this.table_data");
          let arr = [];
          this.table_data.map((item) => {
            if (item.isDefault == "1") {
              arr.push(item.isDefault);
            }
          });
          console.log(arr, "arr是啥");
          if (this.formGroupRuleForm.id && this.formGroupRuleForm.optionType == "1" && arr.length > 1) {
            this.$message.warning("有多个默认项，请先修改默认选项");
          } else {
            this.formGroupRuleForm.schoolId = this.schoolId;
            const classSchedule = new classScheduleModel();
            classSchedule
              .getBehaviorLabelSave(this.formGroupRuleForm)
              .then((res) => {
                this.handleRes(res, () => {
                  this.$message.success(`${this.dialogObj.title}成功`);
                  this.tableftInit();
                  this.handleFormGroupClose();
                  console.log(this.formGroupRuleForm, "this.dialogObj");
                });
              });
          }

        } else {
          //   console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * @Description: 左侧栏添加
     * @DoWhat: 添加
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2022-11-07 16:18:21
     */
    createFormGroup() {
      this.dialogObj.title = "添加";
      this.groupingStatus = "add";
      this.dialogObj.dialogVisible = true;
    },
    // 接口请求结果处理
    handleRes(res, fn) {
      if (res.data.code === "200") {
        fn();
      } else if (res.data && res.data.msg) {
        this.$message.error(res.data.msg);
      }
      /**
       * @Description: 行为数据-编辑
       * @DoWhat: 编辑
       * @UseScenarios:
       * @Attention:
       * @Author: xwn
       * @Date: 2022-11-15 15:13:02
       */
    },
    edit(row) {
      console.log(row, "0000");
      this.dataEdit = row.id;
      this.behaviorData.isDefault = String(row.isDefault);
      this.behaviorData.dataName = row.dataName;
      this.behaviorData.id = row.id;
      this.behaviorData.sort = row.sort;
      this.dialogObj1.title = "编辑";
      this.dialogObj1.dialogVisible = true;
    },
    /**
     * @Description: 行为数据-删除
     * @DoWhat: 删除
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-15 15:14:11
     */
    del(row) {
      let confirmText = ["是否确定删除?", "信息删除后不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", confirmText[i]));
      }
      this.$confirm("确认提示", {
        message: h("div", null, newDatas),
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const param = {
            id: row.id,
          };
          this._fet("/school/schoolBehaviorData/delete", param).then((res) => {
            this.handleRes(res, () => {
              this.$message.success("删除成功!");
              this.getList();
            });
          });
        })
        .catch(() => {
          // 取消
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.left-content {
  width: calc(100% - 290px);
  border-radius: 6px;
  overflow: hidden;
  float: left;
}

.filter-form {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  line-height: 60px;
  padding: 0 20px;
  background-color: #ffffff;

  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808487;
    margin-right: 20px;
  }

  .butn {
    margin-right: 40px;
  }
}

.sel-wrapper {
  display: flex;
  justify-content: center;
}

.btn-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.required-field {
  color: red;
  display: flex;
  align-items: center;
  width: 20px;
}
</style>
